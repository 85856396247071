<template>
  <div>
    <h4 class="my-4">Teklifler</h4>
    <div
      class="d-flex align-items-center justify-content-between search-list-area"
    >
      <div class="d-flex align-items-center search-input mb-4">
        <input placeholder="Ara.." v-model="search" class="form-control mr-2" />
        <select v-model="list_length" class="form-control ml-2">
          <option value="" selected>Seçiniz</option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </select>
      </div>
      <div class="mb-4">
        <b-button
          variant="outline-primary"
          class="ml-4"
          v-b-toggle.sidebar-right
          >Filtrele</b-button
        >
      </div>
    </div>

    <div class="">
      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Belgeler Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.amount="{ item }">
          <p class="text-dark text-bold">
            {{ item.amount }} {{ item.rate === 1 ? "₺" : "$" }}
          </p>
        </template>
        <template v-slot:item.percent="{ item }">
          <p class="text-dark text-bold">{{ item.percent }} %</p>
        </template>
        <template v-slot:item.status="{ item }">
          <b-badge
            variant="success"
            style="font-size: 0.8rem"
            v-if="item.status"
            >Satın alındı</b-badge
          >
        </template>
        <template v-slot:item.isActive="{ item }">
          <b-badge
            variant="warning"
            style="font-size: 0.8rem"
            v-if="!item.isActive"
            >Onaylanmadı</b-badge
          >
          <b-badge
            variant="success"
            style="font-size: 0.8rem"
            v-if="item.isActive"
            >Onaylandı</b-badge
          >
        </template>

        <template v-slot:item.action="{ item }" class="p-0">
          <div class="d-flex justify-content-end">
            <b-button
              :variant="[!item.isActive ? 'outline-success' : 'outline-danger']"
              @click="editModalOptions(item)"
              ><i class="fas fa-check p-0 fa-sm" v-if="!item.isActive"></i>
              <i class="fas fa-times p-0 fa-sm" v-else></i>
            </b-button>
            <b-button
              variant="outline-danger"
              class="ml-2"
              @click="openDeleteItemModal()"
              ><i class="fas fa-trash p-0 fa-sm"></i
            ></b-button>
          </div>
        </template>
      </List>
      <!-- Create and Actions Popups -->
    </div>
    <DeleteOperation modalid="deleteDocument1" />
  </div>
</template>

<style lang="scss" scoped>
@media (max-width: 990px) {
  .search-list-area {
    flex-direction: column;
    .search-input {
      width: 100%;
      * {
        width: 100%;
      }
    }
  }
  .search-buttons {
    flex-direction: column;
  }
}

.badges-area {
  display: flex;
  gap: 1rem;
  p {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: $dveb-primary !important;
  }
  .badges {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    .badge {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      padding: 0 !important;

      &.green {
        background-color: #44b6ae;
      }
      &.yellow {
        background-color: #ffac00;
      }
      &.red {
        background-color: red;
      }
    }
  }
}
.tapu-text {
  span {
    background-color: $dveb-divider;
    padding: 0.2rem 0.5rem;
    border-radius: 0.3rem;
  }
}
</style>

<script>
import moment from "moment";
import Swal from "sweetalert2";

moment.locale("tr");
export default {
  name: "customerList",

  data() {
    return {
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "#", value: "id", sortable: true, size: "60px" },
        { text: "Mülk Adı", sortable: true, value: "property" },
        { text: "Teklif Tarihi", sortable: true, value: "offerDate" },
        { text: "Teklif Miktarı", sortable: true, value: "amount" },
        { text: "Yüzdelik Dilim", sortable: true, value: "percent" },
        { text: "Teklif Durumu", sortable: true, value: "status" },
        { text: "Onaylandı mı?", sortable: true, value: "isActive" },
        { text: "İşlemler", value: "action", size: "60px" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      filterList: [],
      list_length: 5,
      editModal: false,
      editItem: {},
    };
  },

  created() {
    this.search = this.$route.query.search;
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.items = [
        {
          id: 1,
          property: "KARADENİZ APARTMANI",
          offerDate: moment().format("LLL"),
          amount: 1000000,
          rate: 1,
          percent: 12,
          status: 1,
          isActive: 0,
        },
        {
          id: 2,
          property: "KARADENİZ APARTMANI",
          offerDate: moment().format("LLL"),
          amount: 1000000,
          rate: 1,
          percent: 12,
          status: 1,
          isActive: 0,
        },
        {
          id: 3,
          property: "KARADENİZ APARTMANI",
          offerDate: moment().format("LLL"),
          amount: 1000000,
          rate: 1,
          percent: 12,
          status: 1,
          isActive: 0,
        },
      ];
      this.totalItems = this.items.length;

      this.pagination = {
        current_page: 1,
        total_pages: Math.ceil(this.items?.length / this.list_length),
        total_items: this.items.length,
        list_length: this.list_length,
      };
      this.loading = false;
      if (this.search) {
        const searchValue = this.search;
        const foundItems = this.items.filter((item) => {
          for (const prop in item) {
            if (item[prop].toString().toLowerCase().includes(searchValue)) {
              return true;
            }
          }
          return false;
        });
        this.items = foundItems;
      }

      // this.items = [];
      // this.totalItems = 0;

      // let queryLimit = this.perPage ? this.perPage : 10;
      // let query = `user/list?Page=${
      //   this.page ? this.page : 1
      // }&limit=${queryLimit}`;
      // if (this.search) {
      //   query += `&search=${this.search}`;
      // }

      // this.$dbFunctions
      //   .get(query)
      //   .then((res) => {
      //     if (res.success == true && res.count > 0) {
      //       this.items = res.data;
      //       this.totalItems = res.count;

      //       this.pagination = {
      //         current_page: res.page,
      //         total_pages: res.pageCount,
      //         total_items: res.count,
      //       };
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   })
      //   .finally(() => {
      //     this.loading = false;
      //   });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteItemModal() {
      this.$bvModal.show("modal-deleteOperationdeleteDocument1");
    },
    editModalOptions(item) {
      Swal.fire({
        title: "Emin misiniz?",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Evet",
        denyButtonText: `Hayır`,
        cancelButtonText: "İptal",
        icon:"warning"
      }).then((result) => {
        if (result.isConfirmed) {
        var editedItem = this.items.find((i) => i.id === item.id);
          if (editedItem.isActive) {
            editedItem.isActive = 0;
          } else {
            editedItem.isActive = 1;
          }
        } else if (result.isDenied) {
          Swal.fire("Değişiklik kaydedilmedi", "", "info");
        }
      });
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
    list_length: function () {
      this.getList();
    },
    "$refs.badge": function () {
      console.log($refs);
    },
  },
};
</script>
